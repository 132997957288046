import React, {
	useState,
	useEffect,
	useMemo,
	useCallback,
	useRef,
} from "react";
import {
	dateFilter,
	greenValues,
	months,
	numerical,
	redValues,
	strats,
	textFilter,
} from "../data";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import axios from "axios";
import LoaderComp from "./LoaderComp";
import { dateToString, getHiddenValues, transposeData } from "../utils";
// import ColumnsDropdown from "./ColumnsDropdown";
//import TableRow from "./TableRow";

const DataTable = ({
	theadData,
	data,
	setData,
	year,
	selectedStr,
	filterChanges,
	month,
	filterAll,
	type,
	finYear,
	mdd,
	setMdd,
	date,
	date_2,
}) => {
	const gridRef = useRef();
	const [actualData, setActualData] = useState(data);
	const [actualMdd, setActualMdd] = useState(mdd);

	const [rowData, setRowData] = useState(data);
	const [flag, setFlag] = useState(false);

	console.log("data", data);
	console.log("columns", theadData);
	const uscurrencyFormatter = (params) => {
		let formattedCurrency = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
		}).format(params.value);
		return formattedCurrency;
	};

	const mddcurrencyFormatter = (params) => {
		let formattedCurrency = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
		}).format(params.value * -1);
		return formattedCurrency;
	};
	const currencyFormatter = (params) => {
		return typeof params.value === Number
			? params.value.toLocaleString()
			: Math.floor(params.value).toLocaleString();
	};
	const defaultFormatter = (params) => {
		return params.value;
	};

	useEffect(() => {
		setRowData(data);
	}, []);

	function convertToTitleCase(str) {
		// Split the string into words
		if (str === "netPnl") str = "annualisedNetPnl";
		// else if(str === "roc") str = "annualisedRoc"
		const words = str.split(/(?=[A-Z])/);

		// Capitalize the first letter of each word
		const capitalizedWords = words.map(
			(word) => word.charAt(0).toUpperCase() + word.slice(1)
		);

		// Join the words back together with a space
		const result = capitalizedWords.join(" ");

		return result;
	}

	const [visibleCols, setVisibleCols] = useState([
		"startDate",
		"endDate",
		"netPnl",
		"worstPnl",
		"calmarRatio",
		"sharpeRatio",
		"mdd",
		"month",
		"asset",
		"region",
		"roc",
	]);

	const [columnDefs, setColumnDefs] = useState([]);

	useEffect(() => {
		setColumnDefs(
			theadData &&
				theadData.map((th, index) =>
					Object.assign({
						suppressMovable:
							th === "region" || th === "asset" ? true : false,
						cellClass: "suppress-movable-col",
						pinned:
							th === "region" || th === "asset" ? "left" : null,
						field: th,
						flex: 1,
						hide: visibleCols.indexOf(th) === -1 ? true : false,
						headerName:
							th === "worstPnl"
								? convertToTitleCase("worstDayPnl")
								: convertToTitleCase(th),
						cellEditor: "agNumberCellEditor",
						filter:
							dateFilter.indexOf(th) !== -1
								? "agDateColumnFilter"
								: textFilter.indexOf(th) !== -1
								? "agTextColumnFilter"
								: "agNumberColumnFilter",
						cellStyle: (params) => {
							if (redValues.indexOf(th) !== -1) {
								return { color: "red", fontWeight: 500 };
							} else if (greenValues.indexOf(th) !== -1) {
								return { color: "green", fontWeight: 500 };
							} else {
								if (
									(dateFilter.indexOf(th) === -1 ||
										th === "mtmtv") &&
									textFilter.indexOf(th) === -1
								) {
									if (parseFloat(params.value) > 0)
										return {
											color: "green",
											fontWeight: 500,
										};
									else
										return {
											color: "red",
											fontWeight: 500,
										};
								}
							}
						},
						valueFormatter:
							dateFilter.indexOf(th) === -1 &&
							textFilter.indexOf(th) === -1 &&
							th != "mdd" &&
							th != "calmarRatio" &&
							th != "sharpeRatio" &&
							th != "roc"
								? uscurrencyFormatter
								: th === "mdd"
								? mddcurrencyFormatter
								: defaultFormatter,
					})
				)
		);
	}, [visibleCols, theadData]);

	const [content, setContent] = useState(
		getHiddenValues(theadData, visibleCols)
	);

	// const filterChange_live = (event) => {
	//   const filterData = event.api.rowModel.rowsToDisplay.map(
	//     (item) => item.data
	//   );

	//   let netpnl = 0;
	//   let tv = 0;
	//   if (!(selectedStr === "all") || !(month === "all")) {
	//     filterData.forEach((item) => {
	//       netpnl += item.netpnl;
	//       tv += item.tv;
	//     });
	//     filterChanges(netpnl, tv);
	//   } else if (selectedStr === "all" && month === "all") {
	//     filterData.forEach((item) => {
	//       months.forEach((mon) => {
	//         if (mon !== "all") {
	//           netpnl += item[mon];
	//         }
	//       });
	//     });

	//     filterAll(netpnl);
	//   }
	// };

	const defaultColDef = useMemo(() => ({
		sortable: true,
	}));

	return (
		<div>
			{/* <div class="self-end mr-4">
        <ColumnsDropdown
          visibleCols={visibleCols}
          setVisibleCols={setVisibleCols}
          content={content}
          setContent={setContent}
        />
      </div> */}
			<div
				className="ag-theme-alpine"
				style={{
					width: "100%",
					height: 500,
					marginLeft: "auto",
					marginRight: "auto",
				}}
			>
				<AgGridReact
					ref={gridRef}
					rowData={rowData}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					animateRows={true}
					suppressScrollOnNewData={true}
				/>
			</div>
		</div>
	);
};
export default DataTable;
