import React from "react";

const InputCheckbox = ({ refer, val, handleChange, content }) => {
  return (
    <label class="flex mx-2 justify-between">
      <p class="text-md ml-4">{val}</p>
      <input
        ref={refer}
        value={val}
        type="checkbox"
        checked={content.includes(val)}
        onChange={handleChange}
      />
    </label>
  );
};

export default InputCheckbox;
