import React from "react";
import Block from "./Block";

const BlockGrid = (props) => {
  return (
    <div class="grid grid-cols-5 gap-4 mt-6">
      {props.list.map((item) => {
        let val = item.value;
        item.value = val !== "N/A" ? parseFloat(item.value) : "N/A";
        if (
          item.title === "Net Pnl" ||
          item.title === "Annualised Net Pnl" ||
          item.title === "Best Day Pnl" ||
          item.title === "Worst Day Pnl" ||
          (item.title === "Mdd" && item.value !== "N/A")
        ) {
          val =
            item.title !== "Mdd"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(item.value)
              : new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(item.value * -1);
        }
        return (
          <Block
            title={item.title}
            val={val}
            color={
              item.value >= 0
                ? item.title === "Mdd" ||
                  item.title === "Negative Days"
                  ? "text-red-500"
                  : "text-green-500"
                : "text-red-500"
            }
          />
        );
      })}
    </div>
  );
};

export default BlockGrid;
