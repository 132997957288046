import React, { useState } from "react";
import { AgChartsReact } from "ag-charts-react";
import { time } from "ag-charts-community";

const LineChart = (props) => {
  const [lineData, setLineData] = useState(props.data);

  const [options, setOptions] = useState({
    autoSize: true,
    data: lineData,
    title: {
      text: "NetPnl Cumsum vs Date",
      fontSize: 18,
      spacing: 25,
    },
	height: 600,
    series: [
      {
        type: "line",
        xKey: "date",
        yKey: "netpnl",
        stroke: "#247BA0",
        marker: {
          enabled: false,
        },
      },
    ],
    axes: [
      {
        type: "number",
        position: "left",
        // label: {
        // 	formatter: function (params) {
        // 		// Check if params.value is a number and format it as a percentage
        // 		if (!isNaN(params.value)) {
        // 			return params.value.toFixed(2) + "%";
        // 		}
        // 		// Return the original value if it's not a number
        // 		return params.value;
        // 	},
        // },
		gridLine: {
			style: [
			  {
				stroke: "lightgrey",
				lineDash: [5, 5],
			  },
			],
		  },
      },
      {
        type: "category",
        position: "bottom",
		label : {
			autoRotate: false,
		},
        // label: {
        //   formatter: function (params) {
        //     // Check if params.value is a number and format it as a percentage
        //     if (!isNaN(params.value)) {
        //       return params.value.toFixed(2) + "%";
        //     }
        //     // Return the original value if it's not a number
        //     return params.value.substring(0,4)
        //   },
        // },

        gridLine: {
          enabled: true,
          style: [
            {
              stroke: "lightgrey",
              lineDash: [10, 5],
            },
          ],
        },
        // tick: {
        //   interval: time.month.every(2),
        // },
      },
    ],
  });

  return <AgChartsReact options={options} />;
};

export default LineChart;

