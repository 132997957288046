import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthToken } from "../context/token";

const Header = (props) => {
  const navigate = useNavigate();
  const { setAuthToken } = useAuthToken();

  return (
    <div class="py-5 w-full h-fit bg-sky-700 flex justify-between items-center">
      <p class="text-white pl-3 text-2xl font-bold">26 Miles Dashboard</p>
      
      <div class="flex">
       {props.children}
        <div
          class="bg-white flex mr-8 justify-center items-center p-3 rounded hover:scale-110 cursor-pointer"
          onClick={() => {
            localStorage.removeItem('token');
            setAuthToken(null);
            navigate("/");
          }}
        >
          <p class="text-md font-bold">Logout</p>
        </div>
      </div>
    </div>
  );
};

export default Header;
