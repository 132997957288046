import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import DropdownHelper from "../components/DropdownHelper";
import { assetObjs, finYears, regionObjs } from "../data";
import { checkYearsContinuity, convertDate, getArrayByKey } from "../utils";
import { useAuthToken } from "../context/token";
import axios from "axios";
import LoaderComp from "../components/LoaderComp";
import DataTable from "../components/Datatable";
import Block from "../components/Block";
import { CSVLink } from "react-csv";
// import LineCh from "../components/Linechart";
import FilterDropdown from "../components/FilterDropdown";
import StackedBarChart from "../components/StackedBarChart";
import GroupedTable from "../components/GroupedTable";
import showErrorSnackbar from "../components/Snackbar";
import PieChart from "../components/Piechart";
import BlockGrid from "../components/BlockGrid";
import { useNavigate } from "react-router-dom";
import LineChart from "../components/LineChart";

const Dashboard = () => {
  const d = new Date();
  const { token, setAuthToken } = useAuthToken();
  const [asset, setAsset] = useState("all");
  const [region, setRegion] = useState("all");
  const [finYear, setFinYear] = useState(new Date().getFullYear().toString());
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLanding, setIsLanding] = useState(true);
  const [linechartData, setLineChartData] = useState([]);
  const [netPnl, setNetPnl] = useState();
  const [mdd, setMdd] = useState({});
  const [columns, setColumns] = useState([]);
  const [calRatio, setCalRatio] = useState();
  const [sharpeRatio, setSharpeRatio] = useState();
  const [chartData, setChartData] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [landingData, setLandingData] = useState([]);
  const [profitPieData, setProfitPieData] = useState([]);
  const [lossPieData, setLossPieData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [blockObjs, setBlockObjs] = useState([]);
  const [years, setYears] = useState(finYears);
  const [landingLinechartData, setLandingLinechartdata] = useState([]);
  const [yearRegionWiseMap, setYearRegionWiseMap] = useState({});
  const [regionwiseCombinedMap, setRegionwiseCombinedMap] = useState({});
  const backupOverallData = useRef(null);
  const navigate = useNavigate();

  const [assets, setAssets] = useState(
    assetObjs.map((obj) => Object.keys(obj)[0])
  );
  const [regions, setRegions] = useState(
    regionObjs.map((obj) => Object.keys(obj)[0])
  );

  //   const [originalAssets, setOriginalAssets] = useState(
  //     assetObjs.map((obj) => Object.keys(obj)[0])
  //   );
  //   const [originalRegions, setOriginalRegions] = useState(
  //     regionObjs.map((obj) => Object.keys(obj)[0])
  //   );

  const headers = {
    Authorization: `Bearer ${
      token === null ? localStorage.getItem("token") : token
    }`, // Use 'Bearer' followed by a space before the token
    "Content-Type": "application/json", // Set the content type if needed
  };

  // console.log('token', token)

  const emailer = async (message) => {
    try {
      axios.post(
        "/api/bt/sendEmail",
        {
          to: "vaibhav@26milescapital.com",
          subject: "Crash Response for Client Dashboard",
          message: message,
        },
        { headers }
      );
    } catch (err) {
      console.log("emailer error", err.message);
    }
  };
  const fetchYearRegionwise = async () => {
    let responseData;
    let yearRegionMap = {};
    let regionMap = {};
    try {
      responseData = await axios.post(
        "/api/bt/getYearRegionWiseData",
        {
          assets: selectedAssets,
          years: selectedYears,
          regions: selectedRegions,
        },
        {
          headers,
        }
      );

      console.log(responseData.data);
      responseData.data.yearRegionWiseData.forEach((item) => {
        yearRegionMap[`${item.region} ${item.year}`] = {
          calmarRatio: item.calmarRatio,
          sharpeRatio: item.sharpeRatio,
          mdd: item.mdd,
          roc: item.roc,
          worstPnl: item.worstPnl,
          netPnl: item.netPnl,
        };
      });
      responseData.data.regionWiseCombinedData.forEach((item) => {
        regionMap[`${item.region}`] = {
          calmarRatio: checkYearsContinuity(selectedYears)
            ? item.calmarRatio
            : "N/A",
          sharpeRatio: item.sharpeRatio,
          mdd: checkYearsContinuity(selectedYears) ? item.mdd : "N/A",
          roc: item.roc,
          uniqueDates: item.uniqueDates,
          worstPnl: item.worstPnl,
          netPnl: item.netPnl,
        };
      });
    } catch (err) {
      setLoading(false);
      showErrorSnackbar(err.message);
      return;
    }
    const combinedResponse = await fetchOverallData();
    console.log("combined response", combinedResponse);
    regionMap["Combined"] = {
      calmarRatio: checkYearsContinuity(selectedYears)
        ? combinedResponse.overallCalmarRatio
        : "N/A",
      sharpeRatio: combinedResponse.overallSharpeRatio,
      mdd: checkYearsContinuity(selectedYears)
        ? combinedResponse.overallMdd
        : "N/A",
      roc: combinedResponse.overallRoc,
      uniqueDates: combinedResponse.overallUniqueDates,
      worstPnl: combinedResponse.overallWorstPnl,
      netPnl: parseInt(combinedResponse.overallNetPnl),
    };
    // console.log("yearRegionMap", yearRegionMap);
    setYearRegionWiseMap(yearRegionMap);
    setRegionwiseCombinedMap(regionMap);
  };
  const fetchLineChartData = async () => {
    // setLoading(true);

    let responseData;
    let lineChartData = [];
    try {
      responseData = await axios.post(
        "/api/bt/getLineData",
        {
          assets: selectedAssets,
          years: selectedYears,
          regions: selectedRegions,
        },
        {
          headers,
        }
      );

      // console.log(responseData);
      responseData.data.lineData.forEach((item) => {
        const obj = {
          date: convertDate(item.date),
          netpnl: item.netpnl,
        };
        lineChartData.push(obj);
      });
    } catch (err) {
      setLoading(false);
      showErrorSnackbar(err.message);
      return;
    }
    // console.log("isLanding", isLanding);
    setLineChartData(lineChartData);
    if (!landingLinechartData.length) setLandingLinechartdata(lineChartData);
    // console.log("completed year regionwise")
    // setLoading(false);
  };
  const fetchLandingData = async () => {
    // console.log("token", token);
    // console.log("Starting Landing data");
    setLoading(true);
    // console.log(isLoading);
    let responseData;
    let pieProfitData = [];
    let pieLossData = [];
    let rowsArr = [];
    let overallProfit = 0;
    let overallLoss = 0;
    try {
      responseData = await axios.post(
        "/api/bt/getProductwiseData",
        {
          assets: [],
          years: [],
          regions: [],
        },
        {
          headers,
        }
      );
      // console.log(responseData.data);
      // console.log("responseData", responseData);
      responseData.data.productwiseData.forEach((item) => {
        const category = `${item.region} ${item.asset}`;
        // if (item.netPnl === "N/A") item.netPnl = 0;
        // overallNetPnl += item.netPnl;
        item.netPnl = parseInt(item.netPnl);
        const obj = {
          category,
          netPnl: (item.netPnl / item.uniqueDates) * 252,
        };
        // pieData.push(obj);
        if (obj.netPnl !== "N/A" && obj.netPnl >= 0) {
          pieProfitData.push(obj);
          overallProfit += obj.netPnl;
        } else if (obj.netPnl !== "N/A" && obj.netPnl < 0) {
          obj.netPnl = -obj.netPnl;
          pieLossData.push(obj);
          overallLoss += obj.netPnl;
        }
        item.netPnl = parseFloat((item.netPnl / item.uniqueDates) * 252);
      });
    } catch (err) {
      console.log("error happened", err);
      setLoading(false);

      showErrorSnackbar(err.message);
      if (err.response.status === 401 || err.response.status === 403)
        navigate("/");
      return;
      emailer(err.message);
    }
    pieProfitData.length > 0 &&
      pieProfitData.forEach((item) => {
        item.netPnl = (item.netPnl / overallProfit) * 100;
      });
    pieLossData.length > 0 &&
      pieLossData.forEach((item) => {
        item.netPnl = (item.netPnl / overallLoss) * 100;
      });
    responseData.data.productwiseData.map((d) => rowsArr.push(Object.keys(d)));
    // console.log("here");
    setColumns(rowsArr[0]);
    setProfitPieData(pieProfitData);
    setLossPieData(pieLossData);
    setLandingData(responseData.data.productwiseData);

    let blockObjList = [
      {
        title: "Annualised Net Pnl",
        value: parseInt(
          (responseData.data.overallData.overallNetPnl /
            responseData.data.overallData.overallUniqueDates) *
            252
        ),
      },
      {
        title: "Worst Day Pnl",
        value: responseData.data.overallData.overallWorstPnl,
      },
      {
        title: "Best Day Pnl",
        value: responseData.data.overallData.overallBestPnl,
      },
      {
        title: "Positive Days",
        value: responseData.data.overallData.overallPositiveDays,
      },
      {
        title: "Negative Days",
        value: responseData.data.overallData.overallNegativeDays,
      },
      {
        title: "Mdd",
        value: responseData.data.overallData.overallMdd,
      },
      {
        title: "Sharpe Ratio",
        value: responseData.data.overallData.overallSharpeRatio,
      },
      {
        title: "Calmar Ratio",
        value: responseData.data.overallData.overallCalmarRatio,
      },
      {
        title: "ROC",
        value: responseData.data.overallData.overallRoc,
      },
    ];
    if (!backupOverallData.current) {
      backupOverallData.current = blockObjList;
    }
    setBlockObjs(blockObjList);
    await fetchLineChartData();
    setLoading(false);
  };

  const fetchProductwiseData = async () => {
    // console.log("Starting Landing data");
    // setLoading(true);
    // console.log(isLoading);
    let responseData;
    let pieProfitData = [];
    let pieLossData = [];
    let overallProfit = 0;
    let overallLoss = 0;
    try {
      responseData = await axios.post(
        "/api/bt/getProductwiseData",
        {
          assets: selectedAssets,
          years: selectedYears,
          regions: selectedRegions,
        },
        {
          headers,
        }
      );
      responseData.data.productwiseData.forEach((item) => {
        const category = `${item.region} ${item.asset}`;
        // if (item.netPnl === "N/A") item.netPnl = 0;
        // overallNetPnl += item.netPnl;
        item.netPnl = parseInt(item.netPnl);
        const obj = {
          category,
          netPnl: (item.netPnl / item.uniqueDates) * 252,
        };
        // pieData.push(obj);
        if (obj.netPnl !== "N/A" && obj.netPnl >= 0) {
          pieProfitData.push(obj);
          overallProfit += obj.netPnl;
        } else if (obj.netPnl !== "N/A" && obj.netPnl < 0) {
          obj.netPnl = -obj.netPnl;
          pieLossData.push(obj);
          overallLoss += obj.netPnl;
        }
      });
    } catch (err) {
      setLoading(false);
      emailer(err.message);
      showErrorSnackbar(err.message);
      return;
    }
    // responseData.data.tableData.map((d) => rowsArr.push(Object.keys(d)));
    pieProfitData.length > 0 &&
      pieProfitData.forEach((item) => {
        item.netPnl = (item.netPnl / overallProfit) * 100;
      });
    pieLossData.length > 0 &&
      pieLossData.forEach((item) => {
        item.netPnl = (item.netPnl / overallLoss) * 100;
      });
    // setColumns(rowsArr[0]);
    setProfitPieData(pieProfitData);
    setLossPieData(pieLossData);
    // setLandingData(responseData.data.tableData);
    // setNetPnl(responseData.data.overallNetPnl);
    // setOverallBestPnl(responseData.data.overallBestPnl);
    // setOverallWorstPnl(responseData.data.overallWorstPnl);
    // setOverallPositiveDays(responseData.data.overallPositiveDays);
    // setOverallNegativeDays(responseData.data.overallNegativeDays);
    // setLoading(false);
    // console.log("completed productwise")
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    // console.log("stored", storedToken);
    // If a token is found, set it in the state
    if (storedToken) {
      setAuthToken(storedToken);
    }
  }, []);
  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      // You can optionally display a message or perform some other action here
    };

    // Disable the back button listener

    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
    // Cleanup the event listener on component unmount

    const newValues = ["Select All", "Clear All"];
    if (assets.indexOf("Select All") === -1) {
      setAssets([...newValues, ...assets]);
      setSelectedAssets(assets);
    }
    if (regions.indexOf("Select All") === -1) {
      setRegions([...newValues, ...regions]);
      setSelectedRegions(regions);
    }
    if (years.indexOf("Select All") === -1) {
      setYears([...newValues, ...years]);
      setSelectedYears(years);
    }
    fetchLandingData();
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  function getData(tableData, yearsData) {
    // console.log("inside getData")
    // return tableData.map((ele) => {
    // 	let data = { year: ele.year };
    // 	for (let region in ele) {
    // 		if (region === "year") continue;
    // 		console.log(region);

    // 		for (let asset in ele[region]) {
    // 			let newKey = `${region} ${asset}`;
    // 			console.log(newKey);
    // 			data[newKey] = ele[region][asset];
    // 		}
    // 	}
    // 	console.log(data);
    // 	return data;
    // });
    let finalData = [];
    yearsData.map((ele) => {
      let yearwiseData = tableData.filter((item) => item.year === ele);
      let finalObject = { year: ele };
      for (let yearIndex in yearwiseData) {
        let year = yearwiseData[yearIndex];
        let newKey = `${year.region} ${year.asset}`;
        if (year.netPnl !== "N/A") {
          finalObject[newKey] = year.netPnl;
        } else {
          // finalObject[newKey] = 0;
          continue;
        }
      }
      if (Object.keys(finalObject).length > 1) finalData.push(finalObject);
    });
    // console.log("finalData",finalData);
    return finalData;
  }

  const fetchOverallData = async () => {
    let responseData;
    try {
      responseData = await axios.post(
        "/api/bt/getOverallData",
        {
          years: selectedYears,
          assets: selectedAssets,
          regions: selectedRegions,
        },
        { headers }
      );
      // setNetPnl(responseData.data.overallData.overallNetPnl);
      // setNetPnl(responseData.data.overallData.overallNetPnl);
      // setOverallBestPnl(responseData.data.overallData.overallBestPnl);
      // setOverallWorstPnl(responseData.data.overallData.overallWorstPnl);
      // setOverallPositiveDays(responseData.data.overallData.overallPositiveDays);
      // setOverallNegativeDays(responseData.data.overallData.overallNegativeDays);
      let blockObjList = [
        {
          title: "Annualised Net Pnl",
          value:
            responseData.data.overallData.overallUniqueDates === 0
              ? 0
              : parseInt(
                  (responseData.data.overallData.overallNetPnl /
                    responseData.data.overallData.overallUniqueDates) *
                    252
                ),
        },
        {
          title: "Worst Day Pnl",
          value: responseData.data.overallData.overallWorstPnl,
        },
        {
          title: "Best Day Pnl",
          value: responseData.data.overallData.overallBestPnl,
        },
        {
          title: "Positive Days",
          value: responseData.data.overallData.overallPositiveDays,
        },
        {
          title: "Negative Days",
          value: responseData.data.overallData.overallNegativeDays,
        },
        {
          title: "Mdd",
          value: checkYearsContinuity(selectedYears)
            ? responseData.data.overallData.overallMdd
            : "N/A",
        },
        {
          title: "Sharpe Ratio",
          value: responseData.data.overallData.overallSharpeRatio,
        },
        {
          title: "Calmar Ratio",
          value: checkYearsContinuity(selectedYears)
            ? responseData.data.overallData.overallCalmarRatio
            : "N/A",
        },
        {
          title: "ROC",
          value: responseData.data.overallData.overallRoc,
        },
      ];
      setBlockObjs(blockObjList);
    } catch (err) {
      setLoading(false);
      emailer(err.message);
      return showErrorSnackbar(err.message);
    }
    // console.log("completed overall")
    return responseData.data.overallData;
  };

  const fetchYearWiseData = async () => {
    let responseData;
    try {
      responseData = await axios.post(
        "/api/bt/getYearWiseData",
        {
          years: selectedYears,
          assets: selectedAssets,
          regions: selectedRegions,
        },
        { headers }
      );
    } catch (err) {
      setLoading(false);
      emailer(err.message);
      return showErrorSnackbar(err.message);
    }
    // console.log("completed yearwise")
    return responseData.data.yearWiseData;
  };

  const fetchTableData = async () => {
    // if (
    //   selectedAssets.length > 0 &&
    //   selectedRegions.length > 0 &&
    //   selectedYears.length > 0
    // ) {
    // let finalTableData = [];
    let responseData;
    try {
      if (
        selectedAssets.length === 0 ||
        selectedRegions.length === 0 ||
        selectedYears.length === 0
      ) {
        let message = "Please select";
        if (selectedYears.length === 0) message = `${message} years`;
        if (selectedRegions.length === 0) message = `${message} regions`;
        if (selectedAssets.length === 0) message = `${message} assets`;
        throw new Error(`${message} from dropdowns above!`);
      }
      setLoading(true);
      setIsLanding(false);

      if (
        selectedAssets.length === assets.length - 2 &&
        selectedRegions.length === regions.length - 2 &&
        selectedYears.length === years.length - 2
      ) {
        setIsLanding(true);
        await fetchLandingData();
        return;
      }
      responseData = await axios.post(
        "/api/bt/getYearRegionAssetWiseData",
        {
          assets: selectedAssets,
          years: selectedYears,
          regions: selectedRegions,
        },
        { headers }
      );
    } catch (err) {
      console.log("debug", err.message);
      // setFlag(false);
      setLoading(false);
      emailer(err.message);
      return showErrorSnackbar(err.message);
      // return;
    }
    let chartData = getData(responseData.data.tableData, selectedYears);
    // console.log("chartData", chartData);
    setBarChartData(chartData);
    let yearwiseData = await fetchYearWiseData();
    let finalData = [...responseData.data.tableData, ...yearwiseData];
    setTableData(finalData);
    // setNetPnl(responseData.data.overallNetPnl);
    // setNetPnl(responseData.data.overallNetPnl);
    // setOverallBestPnl(responseData.data.overallBestPnl);
    // setOverallWorstPnl(responseData.data.overallWorstPnl);
    // setOverallPositiveDays(responseData.data.overallPositiveDays);
    // setOverallNegativeDays(responseData.data.overallNegativeDays);
    // } else {
    //   const message = `Please select ${
    //     selectedYears.length > 0 ? "" : "years"
    //   } ${selectedAssets.length > 0 ? "" : "assets"} ${
    //     selectedRegions.length > 0 ? "" : "regions"
    //   }`;
    //   console.log("message", message);
    //   return showErrorSnackbar(message);
    // }

    // setFlag(false);
    // const response1 = fetchOverallData();
    const response2 = fetchProductwiseData();
    const response3 = fetchLineChartData();
    const response4 = fetchYearRegionwise();
    const [res2, res3, res4] = await Promise.all([
      // response1,
      response2,
      response3,
      response4,
    ]);
    // console.log("completed");
    setLoading(false);
  };

  // useEffect(() => {
  // 	setChartData([]);
  // 	setTableData([]);
  // }, [selectedRegions, selectedYears, selectedAssets]);

  const downloadDailyReport = async () => {
    try {
      const response = await axios.post(
        "/api/bt/downloadData",
        {
          assets: isLanding ? assets : selectedAssets,
          years: isLanding ? finYears : selectedYears,
          regions: isLanding ? regions : selectedRegions,
          format: "daily",
        },
        { headers }
      );
      const blob = new Blob([response.data], {
        type: "application/json",
      });

      // Create a link element and trigger a download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "dailyReport.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      emailer(err.message);
      return showErrorSnackbar(err.message);
    }
  };

  const downloadMonthlyReport = async () => {
    try {
      const response = await axios.post(
        "/api/bt/downloadData",
        {
          assets: isLanding ? assets : selectedAssets,
          years: isLanding ? finYears : selectedYears,
          regions: isLanding ? regions : selectedRegions,
          format: "month",
        },
        { headers }
      );
      const blob = new Blob([response.data], {
        type: "application/json",
      });

      // Create a link element and trigger a download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "monthlyReport.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      emailer(err.message);
      return showErrorSnackbar(err.message);
    }
  };

  const downloadRawReport = async () => {
    try {
      const response = await axios.post(
        "/api/bt/downloadData",
        {
          assets: isLanding ? assets : selectedAssets,
          years: isLanding ? finYears : selectedYears,
          regions: isLanding ? regions : selectedRegions,
          format: "raw",
        },
        { headers }
      );
      const blob = new Blob([response.data], {
        type: "application/json",
      });

      // Create a link element and trigger a download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "rawReport.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      emailer(err.message);
      return showErrorSnackbar(err.message);
    }
  };

  return (
    <div>
      <Header>
        <div
          class="bg-white flex mr-8 justify-center items-center p-3 rounded hover:scale-110 cursor-pointer"
          onClick={() => {
            let pieProfitData = [];
            let pieLossData = [];
            let overallProfit = 0;
            let overallLoss = 0;

            landingData.forEach((item) => {
              const category = `${item.region} ${item.asset}`;
              // if (item.netPnl === "N/A") item.netPnl = 0;
              // overallNetPnl += item.netPnl;
              const obj = {
                category,
                netPnl: item.netPnl,
              };
              // pieData.push(obj);
              if (obj.netPnl !== "N/A" && obj.netPnl >= 0) {
                pieProfitData.push(obj);
                overallProfit += obj.netPnl;
              } else if (obj.netPnl !== "N/A" && obj.netPnl < 0) {
                obj.netPnl = -obj.netPnl;
                pieLossData.push(obj);
                overallLoss += obj.netPnl;
              }
            });
            pieProfitData.length > 0 &&
              pieProfitData.forEach((item) => {
                item.netPnl = (item.netPnl / overallProfit) * 100;
              });
            pieLossData.length > 0 &&
              pieLossData.forEach((item) => {
                item.netPnl = (item.netPnl / overallLoss) * 100;
              });

            setSelectedAssets([]);
            setSelectedRegions([]);
            setSelectedYears([]);
            setBlockObjs(backupOverallData.current);
            setProfitPieData(pieProfitData);
            setLossPieData(pieLossData);
            setIsLanding(true);
          }}
        >
          <p class="text-md font-bold">Home</p>
        </div>
      </Header>
      {/* <div class="flex w-full">
        <DropdownHelper
          selectedVal={finYear}
          setSelectedVal={setFinYear}
          content={finYears}
          title={"Calendar Year"}
        />
        <DropdownHelper
          selectedVal={asset}
          setSelectedVal={setAsset}
          content={assets}
          title={"Asset Class"}
        />
        <DropdownHelper
          selectedVal={region}
          setSelectedVal={setRegion}
          content={regions}
          title={"Region"}
        />
      </div> */}

      <div class="flex w-3/4 mt-4 ml-5">
        <div class="w-1/5">
          <FilterDropdown
            selectedVals={selectedYears}
            setSelectedVals={setSelectedYears}
            content={years}
            title={"Years"}
          />
        </div>
        <div class="w-1/5 mx-4">
          <FilterDropdown
            selectedVals={selectedAssets}
            setSelectedVals={setSelectedAssets}
            content={assets}
            title={"Assets"}
          />
        </div>
        <div class="w-1/5">
          <FilterDropdown
            selectedVals={selectedRegions}
            setSelectedVals={setSelectedRegions}
            content={regions}
            title={"Regions"}
          />
        </div>
      </div>
      <div
        onClick={fetchTableData}
        class="mt-4 flex justify-center items-center bg-cyan-700 rounded mx-auto shadow-lg h-fit hover:scale-110 w-fit cursor-pointer"
      >
        <p class="text-white text-lg font-bold p-4">Search</p>
      </div>
      {loading ? (
        <div class="flex justify-center items-center mt-6">
          <LoaderComp />
        </div>
      ) : (
        <>
          {isLanding ? (
            <div>
              {landingData.length > 0 ? (
                <>
                  <div class="mt-6 flex flex-col mx-auto w-5/6">
                    <div class="my-4 flex justify-start">
                      <div class="bg-blue-700 flex justify-center items-center rounded-2xl px-3 py-2 hover:scale-105 mr-3">
                        <CSVLink
                          data={landingData.map((obj) => {
                            delete obj["uniqueDates"];
                            return obj;
                          })}
                        >
                          <p class="text-base font-bold  text-white cursor-pointer">
                            Download Summary
                          </p>
                        </CSVLink>
                      </div>
                      <div class="bg-blue-700 flex justify-center items-center rounded-2xl px-3 py-2 hover:scale-105">
                        <p
                          class="text-base font-bold  text-white cursor-pointer"
                          onClick={downloadMonthlyReport}
                        >
                          Download Monthly Report
                        </p>
                      </div>
                      <div class="bg-blue-700 flex justify-center items-center rounded-2xl px-3 py-2 mx-3 hover:scale-105">
                        <p
                          class="text-base font-bold  text-white   cursor-pointer"
                          onClick={downloadDailyReport}
                        >
                          Download Daily Report
                        </p>
                      </div>
                      <div class="bg-blue-700 flex justify-center items-center rounded-2xl px-3 py-2 hover:scale-105">
                        <p
                          class="text-base font-bold  text-white   cursor-pointer"
                          onClick={downloadRawReport}
                        >
                          Download Raw Report
                        </p>
                      </div>
                    </div>
                    <div>
                      {/* <GroupedTable tableData={landingData} /> */}
                      <DataTable data={landingData} theadData={columns} />
                    </div>

                    <div class="my-6">
                      {" "}
                      <BlockGrid list={blockObjs} />{" "}
                    </div>

                    <div class="flex">
                      {profitPieData.length > 0 ? (
                        <PieChart
                          data={profitPieData}
                          title={"Product wise annualised profit"}
                        />
                      ) : (
                        <></>
                      )}
                      {lossPieData.length > 0 ? (
                        <PieChart
                          data={lossPieData}
                          title={"Product wise annualised loss"}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    {linechartData.length > 0 ? (
                      <div class="mb-9 ml-9 mt-9 mr-9">
                        <LineChart data={landingLinechartData} />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : null}
            </div>
          ) : (
            <div class="mt-6 flex flex-col mx-auto w-5/6">
              {tableData.length > 0 ? (
                <>
                  <div>
                    <div class="my-4 flex justify-start">
                      <div class="bg-blue-700 flex justify-center items-center rounded-2xl px-3 py-2 hover:scale-105 mr-3">
                        <CSVLink data={tableData}>
                          <p class="text-base font-bold  text-white cursor-pointer">
                            Download Summary
                          </p>
                        </CSVLink>
                      </div>
                      <div class="bg-blue-700 flex justify-center items-center rounded-2xl px-3 py-2 hover:scale-105">
                        <p
                          class="text-base font-bold  text-white cursor-pointer"
                          onClick={downloadMonthlyReport}
                        >
                          Download Monthly Report
                        </p>
                      </div>
                      <div class="bg-blue-700 flex justify-center items-center rounded-2xl px-3 py-2 mx-3 hover:scale-105">
                        <p
                          class="text-base font-bold  text-white   cursor-pointer"
                          onClick={downloadDailyReport}
                        >
                          Download Daily Report
                        </p>
                      </div>
                      <div class="bg-blue-700 flex justify-center items-center rounded-2xl px-3 py-2 hover:scale-105">
                        <p
                          class="text-base font-bold  text-white   cursor-pointer"
                          onClick={downloadRawReport}
                        >
                          Download Raw Report
                        </p>
                      </div>
                    </div>
                    <div class="">
                      <GroupedTable
                        tableData={tableData}
                        yearRegionwiseMap={yearRegionWiseMap}
                        regionwiseCombinedMap={regionwiseCombinedMap}
                      />
                    </div>
                  </div>
                  <div class="mt-6">
                    {" "}
                    <BlockGrid list={blockObjs} />{" "}
                  </div>
                  <div class="w-full">
                    <div class="flex justify-center items-center">
                      {barChartData.length > 0 ? (
                        <StackedBarChart
                          chartData={barChartData}
                          yearsData={selectedYears}
                        />
                      ) : (
                        <></>
                      )}
                      {profitPieData.length > 0 ? (
                        <PieChart
                          data={profitPieData}
                          title={"Product wise annualised profit"}
                        />
                      ) : (
                        <></>
                      )}
                     
                      {lossPieData.length > 0 ? (
                        <PieChart
                          data={lossPieData}
                          title={"Product wise annualised loss"}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    {linechartData.length > 0 ? (
                      <div class="mb-9">
                        <LineChart data={linechartData} />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>{" "}
                </>
              ) : null}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;

// <>
// 	{tableData.length ? (
// 		<>
// 			<div class="flex flex-col">
// 				<div class="w-fit">
// 					<CSVLink data={tableData} headers={columns}>
// 						<p class="text-base font-bold underline text-start ml-20 mt-12">
// 							Download as csv
// 						</p>
// 					</CSVLink>
// 				</div>
// 			</div>
// 			<div class="flex flex-col w-full mx-auto h-fit mt-4">
// 				<DataTable
// 					data={tableData}
// 					setData={setTableData}
// 					theadData={columns}
// 					year={finYear}
// 					finYear={finYear}
// 					mdd={mdd}
// 					setMdd={setMdd}
// 				/>
// 			</div>
// 			<div class="grid grid-cols-5 gap-4 mt-6">
// 				<Block
// 					title={"Netpnl"}
// 					val={parseFloat(netPnl).toLocaleString()}
// 					color={
// 						netPnl > 0
// 							? "text-green-500"
// 							: "text-red-500"
// 					}
// 				/>
// 				<Block
// 					title={"MDD"}
// 					val={parseFloat(
// 						mdd.maxDiff
// 					).toLocaleString()}
// 					color={"text-red-500"}
// 				/>
// 				<Block
// 					title={"CDD"}
// 					val={
// 						mdd.currDiff === 0
// 							? "-"
// 							: mdd.currDiff.toLocaleString()
// 					}
// 					color={"text-red-500"}
// 				/>
// 				<Block
// 					title={"MDD Period"}
// 					val={
// 						mdd.maxDiff === 0
// 							? NaN
// 							: convertDate(mdd.date_st) +
// 							  "-" +
// 							  convertDate(mdd.date_end)
// 					}
// 					color={"text-black-500"}
// 				/>
// 				<Block
// 					title={"Calmar Ratio"}
// 					val={calRatio}
// 					color={
// 						calRatio > 0
// 							? "text-green-500"
// 							: "text-red-500"
// 					}
// 				/>
// 				<Block
// 					title={"Sharpe Ratio"}
// 					val={sharpeRatio}
// 					color={
// 						sharpeRatio > 0
// 							? "text-green-500"
// 							: "text-red-500"
// 					}
// 				/>
// 				<Block
// 					title={"Best PNL"}
// 					val={Math.floor(
// 						mdd.bestPnl
// 					).toLocaleString()}
// 					color={
// 						mdd.bestPnl > 0
// 							? "text-green-500"
// 							: "text-red-500"
// 					}
// 				/>
// 				<Block
// 					title={"Worst PNL"}
// 					val={Math.floor(
// 						mdd.worstPnl
// 					).toLocaleString()}
// 					color={
// 						mdd.worstPnl > 0
// 							? "text-green-500"
// 							: "text-red-500"
// 					}
// 				/>
// 				{/* {selectedStr !== "all" ? (
//   <>
//     <Block
//       title={"Positve days"}
//       val={mdd.posDays}
//       color={"text-green-500"}
//     />
//     <Block
//       title={"Negative days"}
//       val={mdd.negDays}
//       color={"text-red-500"}
//     />
//     {month === "all" ? (
//       <Block
//         title={"Annualised PNL"}
//         val={(Math.floor(annualisedPnl) * 252).toLocaleString()}
//         color={
//           annualisedPnl > 0 ? "text-green-500" : "text-red-500"
//         }
//       />
//     ) : null}
//     <Block
//       title={"Best day"}
//       val={Math.floor(bestPnl).toLocaleString()}
//       color={bestPnl > 0 ? "text-green-500" : "text-red-500"}
//     />
//     <Block
//       title={"Worst Day"}
//       val={Math.floor(worstPnl).toLocaleString()}
//       color={worstPnl > 0 ? "text-green-500" : "text-red-500"}
//     />{" "}
//   </>
// ) : null} */}
// 			</div>
// 			<div class="mx-auto my-10">
// 				<LineCh
// 					data={chartData}
// 					xKey={"date"}
// 					yKey={"netPnl"}
// 					maxiVal={mdd.maxiVal}
// 					minVal={mdd.minVal}
// 				/>
// 			</div>{" "}
// 		</>
// 	) : (
// 		<p class="font-bold text-center mt-6 text-lg">
// 			No data exists!{" "}
// 		</p>
// 	)}
// </>
