import React, { createContext, useContext, useState } from "react";

// Create a context
const TokenContext = createContext();

// Custom hook to use the token
export function useAuthToken() {
	const context = useContext(TokenContext);
	if (context === undefined) {
		throw new Error("useAuthToken must be used within a TokenProvider");
	}
	return context;
}

// Create a provider component
export function TokenProvider({ children }) {
	const [token, setToken] = useState(null);
	// store email information for access
	const setAuthToken = (newToken) => {
		setToken(newToken);
	};

	return (
		<TokenContext.Provider
			value={{ token, setAuthToken}}
		>
			{children}
		</TokenContext.Provider>
	);
}
