"use strict";

import React, { useState } from "react";
import { AgChartsReact } from "ag-charts-react";

// const DUMMY_DATA = [
//   {
//     year: "2016",
//     India: {
//       index: 41,
//       options: 24,
//     },
//     US: {
//       index: 42,
//       options: 35,
//     },
//     Brazil: {
//       index: 27,
//       options: 52,
//     },
//   },
//   {
//     year: "2017",
//     India: {
//       index: 42,
//       options: 52,
//     },
//     US: {
//       index: 29,
//       options: 52,
//     },
//     Brazil: {
//       index: 42,
//       options: 35,
//     },
//   },
//   {
//     year: "2018",
//     India: {
//       index: 42,
//       options: 52,
//     },
//     US: {
//       index: 64,
//       options: 49,
//     },
//     Brazil: {
//       index: 42,
//       options: 28,
//     },
//   },
// ];

// const CHART_DATA = DUMMY_DATA.map((ele) => {
//   let data = { year: ele.year };
//   for (let region in ele) {
//     if (region === "year") continue;
//     // console.log(region);

//     for (let asset in ele[region]) {
//       let newKey = `${region} ${asset}`;
//       // console.log(newKey);
//       data[newKey] = ele[region][asset];
//     }
//   }
//   // console.log(data);
//   return data;
// });

function getData(tableData, yearsData) {
  // return tableData.map((ele) => {
  // 	let data = { year: ele.year };
  // 	for (let region in ele) {
  // 		if (region === "year") continue;
  // 		console.log(region);

  // 		for (let asset in ele[region]) {
  // 			let newKey = `${region} ${asset}`;
  // 			console.log(newKey);
  // 			data[newKey] = ele[region][asset];
  // 		}
  // 	}
  // 	console.log(data);
  // 	return data;
  // });
  let finalData = [];
  yearsData.map((ele) => {
    let yearwiseData = tableData.filter((item) => item.year === ele);
    let finalObject = { year: ele };
    for (let yearIndex in yearwiseData) {
      let year = yearwiseData[yearIndex];
      let newKey = `${year.region} ${year.asset}`;
      if (year.netPnl !== "N/A" || year.netPnl >= 0) {
        finalObject[newKey + "_pos"] = year.netPnl;
      } else {
        finalObject[newKey] = 0;
      }
    }
    finalData.push(finalObject);
  });
  // console.log(finalData);
  finalData.sort((a,b) => a.year - b.year)
  return finalData;
}

function getSeriesData(CHART_DATA) {
  let dataItems = [];
  let maxLenIdx = 0,
    maxLen = 0;
  for (let idx in CHART_DATA) {
    if (maxLen < Object.keys(CHART_DATA[idx]).length) {
      maxLen = Object.keys(CHART_DATA[idx]).length;
      maxLenIdx = idx;
    }
  }
  for (let key in CHART_DATA[maxLenIdx]) {
    let data = {
      type: "bar",
      xKey: "year",
    };
    if (key === "year" || key === "region") continue;
    let columnName = key.split(" ");
    let region = columnName[0];
    data.yKey = key;
    data.yName = key;
    data.stackGroup = region;
    // data["normalizedTo"] = 100;
    dataItems.push(data);
  }
//   console.log("series Dt", dataItems);
  return dataItems;
}

const StackedBarChart = (props) => {
  let yearsData = props.yearsData;
  let chartData = props.chartData;
  chartData.sort((a,b) => a.year - b.year)
  console.log("barchartData", chartData);

  let seriesData = getSeriesData(chartData);

  const [options, setOptions] = useState({
    theme: {
      overrides: {
        column: {
          axes: {
            category: {
              groupPaddingInner: 0,
            },
          },
        },
      },
    },
    title: {
      text: "Region wise Net Pnl by Asset category",
    },
    subtitle: {
      text: "in U.S. dollars",
    },
    data: chartData,
    height: 600,
    series: seriesData,
    axes: [
      {
        type: "number",
        position: "left",
        label: {
          formatter: (params) => {
            if (params.value === "N/A") return "N/A";
            const formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            });
            if (params.value !== undefined)
              return formatter.format(Number(params.value));
          },
        },
      },
      {
        type: "category",
        position: "bottom",
      },
    ],
  });

  return (
    <div class="mt-24 w-full">
      <AgChartsReact options={options} />{" "}
    </div>
  );
};

export default StackedBarChart;
