"use strict";

import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
} from "react";
// import { createRoot } from 'react-dom/client';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// Sample data
const rowData = [
  {
    region: "India",
    year: 2017,
    asset: "options",
    netpnl: 120,
    calmarratio: 1.2,
    worstdrawdown: -120,
  },
  {
    region: "India",
    year: 2018,
    asset: "options",
    netpnl: 120,
    calmarratio: 1.2,
    worstdrawdown: -120,
  },
  {
    region: "India",
    year: 2017,
    asset: "futures",
    netpnl: 120,
    calmarratio: 1.2,
    worstdrawdown: -120,
  },
  {
    region: "India",
    year: 2018,
    asset: "options",
    netpnl: 120,
    calmarratio: 1.2,
    worstdrawdown: -120,
  },
  {
    region: "India",
    year: 2017,
    asset: "commodities",
    netpnl: 120,
    calmarratio: 1.2,
    worstdrawdown: -120,
  },
  {
    region: "US",
    year: 2017,
    asset: "index",
    netpnl: 120,
    calmarratio: 1.2,
    worstdrawdown: -120,
  },
  {
    region: "US",
    year: 2017,
    asset: "commodities",
    netpnl: 120,
    calmarratio: 1.2,
    worstdrawdown: -120,
  },
  {
    region: "Brazil",
    year: 2017,
    asset: "index",
    netpnl: 120,
    calmarratio: 1.2,
    worstdrawdown: -120,
  },
  {
    region: "Brazil",
    year: 2017,
    asset: "commodities",
    netpnl: 120,
    calmarratio: 1.2,
    worstdrawdown: -120,
  },
];
// Define the React component
const GroupedTable = (props) => {
  //   const uscurrencyFormatter = (params) => {
  //     let formattedCurrency;
  //     if (typeof params.value === Number) {
  //       formattedCurrency = new Intl.NumberFormat("en-US", {
  //         style: "currency",
  //         currency: "USD",
  //       }).format(params.value);
  //     }
  // 	console.log(formattedCurrency)
  //     return formattedCurrency;
  //   };

  const CurrencyFormatter = ({ value }) => {
    // Format the number as currency (USD)
    const formattedAmount = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);

    return <span>{formattedAmount}</span>;
  };
  function removeAggFuncHeader(params) {
    // Use this function to customize the header value
    return "calmarRatio"; // Use the original header name without the aggFunc
  }

  // Define column definitions
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Region",
      minWidth: 200,
      showRowGroup: "region",
      cellRenderer: "agGroupCellRenderer",
      suppressMovable: true,
      pinned: "left",
    },
    {
      headerName: "Year",
      minWidth: 200,
      showRowGroup: "year",
      cellRenderer: "agGroupCellRenderer",
      suppressMovable: true,
      pinned: "left",
    },
    {
      field: "region",
      rowGroup: true,
      hide: true,
    },
    {
      field: "year",
      rowGroup: true,
      hide: true,
      sortable: true,
      sort: "asc",
    },
    { field: "asset", suppressMovable: true, pinned: "left" },
    {
      headerName: "Net Pnl",
      field: "netPnl",
      suppressMovable: true,
      cellStyle: (params) => {
        if (parseFloat(params.value) > 0)
          return { color: "green", fontWeight: 500 };
        else return { color: "red", fontWeight: 500 };
      },
      valueFormatter: (params) => {
        if (params.value === "N/A") return "N/A";
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        if (params.value !== undefined)
          return formatter.format(Number(params.value));
      },
	//   aggFunc: "sum",
      aggFunc: (params) => {
        // console.log(params);
        // console.log(
        // 	"grouped table year region",
        // 	props.yearRegionwiseMap
        // );
        if (params.rowNode.parent.key === null) {
          if (
            !props.regionwiseCombinedMap.hasOwnProperty(
              `${params.rowNode.allLeafChildren[0].data.region}`
            )
          )
            return;
        //   console.log(props.regionwiseCombinedMap);
          return props.regionwiseCombinedMap[
            `${params.rowNode.allLeafChildren[0].data.region}`
          ]["netPnl"];
        }

        if (
          !props.yearRegionwiseMap.hasOwnProperty(
            `${params.rowNode.allLeafChildren[0].data.region} ${params.rowNode.allLeafChildren[0].data.year}`
          )
        )
          return;
        return props.yearRegionwiseMap[
          `${params.rowNode.allLeafChildren[0].data.region} ${params.rowNode.allLeafChildren[0].data.year}`
        ]["netPnl"];
      },
    },
    {
      field: "calmarRatio",
      cellStyle: (params) => {
        if (parseFloat(params.value) > 0)
          return { color: "green", fontWeight: 500 };
        else return { color: "red", fontWeight: 500 };
      },
      aggFunc: (params) => {
        // console.log(params);
        // console.log(
        // 	"grouped table year region",
        // 	props.yearRegionwiseMap
        // );

        if (params.rowNode.parent.key === null) {
          if (
            !props.regionwiseCombinedMap.hasOwnProperty(
              `${params.rowNode.allLeafChildren[0].data.region}`
            )
          )
            return;
        //   console.log(props.regionwiseCombinedMap);
          return props.regionwiseCombinedMap[
            `${params.rowNode.allLeafChildren[0].data.region}`
          ]["calmarRatio"];
        }

        if (
          !props.yearRegionwiseMap.hasOwnProperty(
            `${params.rowNode.allLeafChildren[0].data.region} ${params.rowNode.allLeafChildren[0].data.year}`
          )
        )
          return;
        return props.yearRegionwiseMap[
          `${params.rowNode.allLeafChildren[0].data.region} ${params.rowNode.allLeafChildren[0].data.year}`
        ]["calmarRatio"];
      },
    },
    {
      field: "sharpeRatio",
      cellStyle: (params) => {
        if (parseFloat(params.value) > 0)
          return { color: "green", fontWeight: 500 };
        else return { color: "red", fontWeight: 500 };
      },
      aggFunc: (params) => {
        // console.log(params);
        // console.log(
        // 	"grouped table year region",
        // 	props.yearRegionwiseMap
        // );

        if (params.rowNode.parent.key === null) {
          if (
            !props.regionwiseCombinedMap.hasOwnProperty(
              `${params.rowNode.allLeafChildren[0].data.region}`
            )
          )
            return;
          // console.log(props.regionwiseCombinedMap)
          return props.regionwiseCombinedMap[
            `${params.rowNode.allLeafChildren[0].data.region}`
          ]["sharpeRatio"];
        }

        if (
          !props.yearRegionwiseMap.hasOwnProperty(
            `${params.rowNode.allLeafChildren[0].data.region} ${params.rowNode.allLeafChildren[0].data.year}`
          )
        )
          return;
        return props.yearRegionwiseMap[
          `${params.rowNode.allLeafChildren[0].data.region} ${params.rowNode.allLeafChildren[0].data.year}`
        ]["sharpeRatio"];
      },
    },
    {
      headerName: "Worst Day Pnl",
      field: "worstPnl",
      cellStyle: (params) => {
        if (parseFloat(params.value) > 0)
          return { color: "green", fontWeight: 500 };
        else return { color: "red", fontWeight: 500 };
      },
      valueFormatter: (params) => {
        if (params.value === "N/A") return "N/A";
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        if (params.value !== undefined)
          return formatter.format(Number(params.value));
      },
      aggFunc: (params) => {
        // console.log(params);
        // console.log(
        // 	"grouped table year region",
        // 	props.yearRegionwiseMap
        // );
        if (params.rowNode.parent.key === null) {
          if (
            !props.regionwiseCombinedMap.hasOwnProperty(
              `${params.rowNode.allLeafChildren[0].data.region}`
            )
          )
            return;
          // console.log(props.regionwiseCombinedMap)
          return props.regionwiseCombinedMap[
            `${params.rowNode.allLeafChildren[0].data.region}`
          ]["worstPnl"];
        }

        if (
          !props.yearRegionwiseMap.hasOwnProperty(
            `${params.rowNode.allLeafChildren[0].data.region} ${params.rowNode.allLeafChildren[0].data.year}`
          )
        )
          return;
        return props.yearRegionwiseMap[
          `${params.rowNode.allLeafChildren[0].data.region} ${params.rowNode.allLeafChildren[0].data.year}`
        ]["worstPnl"];
      },
    },
    {
      field: "mdd",
      cellStyle: { color: "red", fontWeight: 500 },
      valueFormatter: (params) => {
        if (params.value === "N/A") return "N/A";
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        if (params.value !== undefined)
          return formatter.format(Number(params.value * -1));
      },
      aggFunc: (params) => {
        // console.log(params);
        // console.log(
        // 	"grouped table year region",
        // 	props.yearRegionwiseMap
        // );
        if (params.rowNode.parent.key === null) {
          if (
            !props.regionwiseCombinedMap.hasOwnProperty(
              `${params.rowNode.allLeafChildren[0].data.region}`
            )
          )
            return;
          // console.log(props.regionwiseCombinedMap)
          return props.regionwiseCombinedMap[
            `${params.rowNode.allLeafChildren[0].data.region}`
          ]["mdd"];
        }

        if (
          !props.yearRegionwiseMap.hasOwnProperty(
            `${params.rowNode.allLeafChildren[0].data.region} ${params.rowNode.allLeafChildren[0].data.year}`
          )
        )
          return;
        return props.yearRegionwiseMap[
          `${params.rowNode.allLeafChildren[0].data.region} ${params.rowNode.allLeafChildren[0].data.year}`
        ]["mdd"];
      },
    },
    {
      field: "roc",
      cellStyle: (params) => {
        if (parseFloat(params.value) > 0)
          return { color: "green", fontWeight: 500 };
        else return { color: "red", fontWeight: 500 };
      },
      aggFunc: (params) => {
        // console.log(params);
        // console.log(
        // 	"grouped table year region",
        // 	props.yearRegionwiseMap
        // );
        if (params.rowNode.parent.key === null) {
          if (
            !props.regionwiseCombinedMap.hasOwnProperty(
              `${params.rowNode.allLeafChildren[0].data.region}`
            )
          )
            return;
          // console.log(props.regionwiseCombinedMap)
          return props.regionwiseCombinedMap[
            `${params.rowNode.allLeafChildren[0].data.region}`
          ]["roc"];
        }

        if (
          !props.yearRegionwiseMap.hasOwnProperty(
            `${params.rowNode.allLeafChildren[0].data.region} ${params.rowNode.allLeafChildren[0].data.year}`
          )
        )
          return;
        return props.yearRegionwiseMap[
          `${params.rowNode.allLeafChildren[0].data.region} ${params.rowNode.allLeafChildren[0].data.year}`
        ]["roc"];
      },
    },
  ]);
  // Set default grid options
  // const defaultColDef = {
  // 	sortable: true,
  // 	filter: true,
  // };
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      // sortable: true,
      resizable: true,
      // sort: "desc",
    };
  }, []);
  // Set the grid data
  const gridOptions = {
    columnDefs: columnDefs,
    rowData: props.tableData,
    suppressAggFuncInHeader: true,
    defaultColDef: defaultColDef,
  };
  //   const [gridData, setGridData] = useState(rowData);
  // Ag-Grid events
  const onGridReady = (params) => {
    // You can do additional setup here if needed
    return <span style={{ marginLeft: 60 }}>{params.value}</span>;
  };
  return (
    <div
      className="ag-theme-alpine"
      style={{
        width: "100%",
        height: 500,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <AgGridReact
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        animateRows={true}
        groupDisplayType="custom"
      />
    </div>
  );
};
// Export the component
export default GroupedTable;
