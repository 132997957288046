import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const showErrorSnackbar = (message) => {
	toast.error(message, {
		position: toast.POSITION.TOP_RIGHT,
		autoClose: 3000, // Set the duration for the snackbar
	});
};
export default showErrorSnackbar;
