import { useEffect, useState } from "react";
import { AgChartsReact } from "ag-charts-react";
import { formatDataForPieChart } from "../utils";

const getTotalValue = (pieChartData, key) => {
	let totalValue = 0;
	for (let item of pieChartData) {
		totalValue += item[key];
	}
	return totalValue;
};

const PieChart = (props) => {
	const [rowData, setRowData] = useState(props.data);
	const [chartData, setChartData] = useState(formatDataForPieChart(rowData));
	const [overallSum, setOverallSum] = useState(100);

	const handleLegendItemClick = (params) => {
		// const { data } = params;
		let sum = overallSum;
		if (params.enabled) {
			sum += rowData[params.itemId].netPnl;
		} else {
			sum -= rowData[params.itemId].netPnl;
		}
		setOverallSum(sum);
	};
	const formattedData = formatDataForPieChart(rowData);
	// const dummyData = [
	//   { value: 56.9 },
	//   { value: 22.5 },
	//   { value: 6.8 },
	//   { value: 8.5 },
	//   { value: 2.6 },
	//   { value: 1.9 },
	// ]
	// console.log(formattedData);
	const chartOptions = {
		data: rowData,
		title: {
			text: props.title,
		},
		subtitle: {
			text: "in percentage",
		},
		legend: {
			maxWidth:500,
			listeners: {
				legendItemClick: (params) => {
					console.log("params", params);
					handleLegendItemClick(params);
				}, // Handle legend item click event
			},
			item: {
				toggleSeriesVisible: true,
			},
		},
		series: [
			{
				// showInLegend: true,
				// visible: true,
				type: "pie",
				angleKey: "netPnl",
				calloutLabelKey: "category",
				tooltip: {
					renderer: (params) => {
						return {
							title: params.datum["category"],
							content: `${(
								(params.datum["netPnl"] / overallSum) *
								100
							).toFixed(2)}%`,
						};
					},
				},
			},
		],
	};

	return (
		<div className="ag-theme-alpine" style={{ height: 450, width: "100%"}}>
			<AgChartsReact options={chartOptions} />
		</div>
	);
};

export default PieChart;
