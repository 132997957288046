export const getArrayByKey = (arrayOfObjects, key) => {
  for (const obj of arrayOfObjects) {
    const array = obj[key];
    if (array) {
      return array;
    }
  }
  return null;
};

export const convertDate = (date) => {
  let month = date.slice(4, 6);
  const day = date.slice(6);
  const year = date.slice(0, 4);
  if (parseInt(month) < 10) {
    month = month[1];
  }
  return `${day}/${month}/${year}`;
};

export const getHiddenValues = (arr1, arr2) => {
  const arr = [];
  arr1 &&
    arr1.length &&
    arr1.forEach((val) => {
      if (arr2.indexOf(val) === -1) arr.push(val);
    });
  return arr;
};

export const formatDataForPieChart = (data) => {
  return data.map(item => ({
    category: item.category,
    netPnl: item.netPnl,
  }));
};

export const checkYearsContinuity = (years) => {
  years.sort((a, b) => a - b);

  for (let i = 0; i < years.length - 1; i++) {
    if (parseInt(years[i + 1]) - parseInt(years[i]) !== 1) return false;
  }
  return true;
};